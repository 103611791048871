<template>
    <div>
        <h1>
            Search results for: {{ text }}
        </h1>

        <div v-if="invalidParameters">
            Oops, something has gone wrong, sorry
        </div>
        <div v-else-if="isLoading">
            Searching, please wait ...
        </div>
        <div v-else-if="hasErrored">
            {{ errorMessage || 'Sorry, an error has occurred' }}
        </div>
        <div v-else-if="results.length === 0">
            No results found
        </div>
        <search-result-list v-else :results="results" @view-entry="onView" />
    </div>
</template>

<script>
import SearchResultList from '@/components/SearchResultList'
import store from '@/infrastructure/store'

export default {
    name: 'search',
    components: {
        SearchResultList
    },
    props: {
        text: { type: String, required: true },
    },
    inject: [ 'api' ],
    data() {
        return {
            sharedState: store.state,
            invalidParameters: false,
            results: [],
            isLoading: false,
            hasErrored: false,
            errorMessage: '',
        }
    },
    mounted() {
        if (!this.text) {
            this.invalidParameters = true
            return
        }

        this.performSearch()
    },
    methods: {
        performSearch() {
            this.isLoading = true
            this.hasErrored = false

            this.api.searchByName(this.$route.query.crematorium, this.text, this.sharedState.staffPin)
                .then(response => {
                    this.results = response.data      
                })
                .catch(error => {
                    console.error(error)
                    this.errorMessage = 'Sorry, we were unable to perform the search at this time. Please try again later'
                    this.hasErrored = true
                })
                .then(() => {
                    this.isLoading = false
                })
        },

        onView(entry) {
            this.$router.push({ path: '/entry', query: { crematorium: this.$route.query.crematorium, entryId: entry.entryId }})
        },
    },
}
</script>

<style scoped lang="scss">
    h1 {
        font-size: 20px;
        font-weight: normal;
        text-transform: none;
    }
</style>