<template>
  <main v-if="loading" class="loading">
    Loading, please wait...
  </main>
  <div v-else id="app">
    <div class="ie-warning" :class="{ 'full-screen': $route.meta.fullScreen }">
      <span class="ie-warning-text">
        Your browser is out of date. Please download Microsoft's replacement browser Edge by <a href="https://www.microsoft.com/edge">clicking here</a>,
        or use another browser such as <a href="https://www.google.com/intl/en_uk/chrome/">Google Chrome</a> or <a href="https://www.mozilla.org/en-GB/firefox/new/">Mozilla Firefox</a>.
      </span>
    </div>
    <site-header v-show="!$route.meta.fullScreen">
      <div v-if="headerContent" v-html="headerContent"></div>
    </site-header>
    <main :class="{ 'full-screen': $route.meta.fullScreen }">
      <div class="main-panel" :style="{ 'flex-grow': $route.meta.fullScreen ? 1 : undefined }">
        <p v-if="hasErrored">
          Sorry, something has gone wrong. Please try again later.
        </p>
        <template v-else>
          <router-view />

          <div v-if="!$route.meta.isHome && !$route.meta.fullScreen" class="bottom-buttons">
            <button type="button" @click="$router.go(-1)" class="back-button">
              Go Back
            </button>
            
            <router-link to="/" class="button">
              Start Again
            </router-link>
          </div>
        </template>
      </div>
    </main>
    <site-footer v-show="!$route.meta.fullScreen" v-if="footerContent" v-html="footerContent" />
  </div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import Api from '@/infrastructure/api'
import store from '@/infrastructure/store'
import { UPLOAD_SRC } from '@/settings'

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter,
  },
  provide() {
    return {
      api: this.api,
    }
  },
  data() {
    return {
      loading: true,
      hasErrored: false,
      api: new Api(),
      headerContent: '',
      footerContent: '',
    }
  },
  mounted() {
    this.setBackgroundImage()
    this.loadCrematoriumDetails()
  },
  watch: {
    '$route.query.crematorium' () {
      this.setBackgroundImage()
      this.loadCrematoriumDetails()
    }
  },
  methods: {
    loadCrematoriumDetails() {
      this.loading = true     

      this.api.getCrematoriumDetails(this.$route.query.crematorium)
        .then(response => {
          if (!response || !response.data) {
            console.error('No response received')
            this.hasErrored = true
            return
          }

          const cremDetails = response.data

          if (cremDetails.useHeaderContent) {
            this.headerContent = this.parseHtml(cremDetails.headerContent)
          } else {
            this.headerContent = ''
          }

          if (cremDetails.useFooterContent) {
            this.footerContent = this.parseHtml(cremDetails.footerContent)
          } else {
            this.footerContent = ''
          }

          store.setCrematoriumDetails(cremDetails)

          this.hasErrored = false
        })
        .catch(error => {
          console.error(error)
          this.hasErrored = true

          // Retry again in a few seconds
          setTimeout(() => this.loadCrematoriumDetails, 3000)
        })
        .then(() => {
          // Always
          this.loading = false
        })
    },
    constructDataUrl(contentType, content) {
      const url = 'data:' + contentType + ';base64,' + content
      return 'url(' + url + ')'
    },
    setBackgroundImage() {
      document.body.style.backgroundImage = this.constructCssBackgroundUrl(this.$route.query.crematorium || window.location.hostname)
    },
    constructCssBackgroundUrl(hostname) {
      if (hostname) {
        const parts = hostname.split('.')

        if (parts.length > 0) {
          const hostname = parts[0].toLowerCase()
          return 'url("' + UPLOAD_SRC + '/Uploads/Images/Backgrounds/' + hostname + '_background.jpg")'
        }
      }
    },
    parseHtml(html) {
      if (!html) {
        return html
      }

      // TODO This is a very crude way of fixing the image paths
      return html.replace(' src="/Uploads/', ' src="'+ UPLOAD_SRC + '/Uploads/')
    },
  }
}
</script>

<style lang="scss">
  @import '@/scss/_global';
</style>
<style scoped lang="scss">
  @import '@/scss/_screen';
  @import '@/scss/_button';
  @import '@/scss/_mixins';
  
  $font-family: 'Open Sans', sans-serif;

  .loading {
    background-color: white;
    width: 12rem;
    margin: 2rem auto;
  }

  header, footer {
    flex-grow: 0;
  }

  #app {
    display: flex;
    flex-direction: column;
  }

  main {
    font-family: $font-family;
    color: rgb(80, 80, 80);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    color: black;
    text-align: center;
    position: relative;
    padding: 20px;

    &.full-screen {
      padding: 0 20px;

      @include ie-only {
       height: 100vh;
      }
    }
    
    .main-panel {
      display: flex;
      flex-direction: column;
      position: relative;
      border: none;
      background-color: rgba(255, 255, 255, 0.75);
      padding: 10px;
      border-radius: 0;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      max-width: 1800px;

      @include desktop {
        padding-left: 30px;
        padding-right: 30px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .bottom-buttons {
      margin: 15px 10px 10px;

      a, button {
        min-width: 120px;
      }
    }
  }

  .ie-warning {
    display: none;
    font-family: $font-family;
    margin: 0;
    padding: .8rem 4rem;
    font-size: 1.1rem;
    text-align: center;
    letter-spacing: .5px;
    line-height: 1.5;
    background-color: darksalmon;
    font-weight: normal;
    
    &:not(.full-screen) {
      @include ie-only {
        display: block;
      }
    }

    .ie-warning-text {
      display: block;
      max-width: 1000px;
      margin: 0 auto;
    }

    a {
      white-space: nowrap;
    }
  }
</style>