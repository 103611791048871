<template>
    <div>
        <h1>Staff Login</h1>

        <p>Please enter the Crematorium's Staff PIN</p>

        <form method="post" @submit.prevent="checkPin">
            <input type="password" required ref="staffPin" v-model.trim="staffPin" :disabled="isLoading" />

            <button type="submit" :disabled="isLoading">
                Go
            </button>

            <p v-if="hasErrored" class="error">
                {{ errorMessage }}
            </p>
        </form>
    </div>
</template>

<script>
import store from '@/infrastructure/store'

export default {
    data() {
        return {
            sharedState: store.state,
            isLoading: false,
            hasErrored: false,
            errorMessage: null,
            staffPin: '',
        }
    },
    inject: ['api'],
    mounted() {
        this.focus()
    },
    methods: {
        focus() {
            this.$refs.staffPin.focus()
        },
        checkPin() {
            this.isLoading = true

            this.api.checkStaffPin(this.$route.query.crematorium, this.staffPin)
                .then(response => {
                    if (response.data && response.data.matched) {
                        this.hasErrored = false
                        this.errorMessage = null
                        store.setStaffPin(this.staffPin)
                        this.$router.push({ path: '/', query: { crematorium: this.$route.query.crematorium }})
                    } else {
                        this.errorMessage = "Incorrect PIN. Please check and try again."
                        this.hasErrored = true
                        this.isLoading = false

                        this.$nextTick(() => this.focus())
                    }
                })
                .catch(error => {
                    console.error(error)
                    this.errorMessage = "Something has gone wrong and we are unable to check your PIN at this time. Please try again later"
                    this.hasErrored = true
                })
                .then(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>

<style scoped lang="scss">
    input[type=password] {
        border: 1px solid black;
        font-size: 1rem;
        padding: 9px;
        margin: 1rem;
    }

    .error {
        margin: 1rem;
        background-color: white;
        border: 0.8px solid grey;
        padding: 9px;
        color: darkred;
        font-weight: bold;

    }
</style>