import store from '@/infrastructure/store'

const SearchOption = Object.freeze({
    ALL: "All",
    STAFF_ONLY: "StaffOnly",
    NOT_AVAILABLE: "NotAvailable",
})

export default {
    data() {
        return {
            sharedState: store.state,
        }
    },
    methods: {
        staffLogout() {
            store.clearStaffPin()
        },
        isSearchOptionAllowed(searchOption) {
            return searchOption === SearchOption.ALL || (this.isStaff && searchOption == SearchOption.STAFF_ONLY)
        },
    },
    computed: {
        crematoriumDetails() {
            return this.sharedState.crematoriumDetails
        },
        childCrematoriums() {
            return this.crematoriumDetails && this.crematoriumDetails.childCrematoriums ? this.crematoriumDetails.childCrematoriums : []
        },
        allowSearchByDate() {
            return this.crematoriumDetails && this.isSearchOptionAllowed(this.crematoriumDetails.dateSearchOption)
        },
        allowSearchByName() {
            return this.crematoriumDetails && this.isSearchOptionAllowed(this.crematoriumDetails.nameSearchOption)
        },
        allowSearchByPin() {
            return this.crematoriumDetails && this.isSearchOptionAllowed(this.crematoriumDetails.pinSearchOption)
        },
        showStaffAccess() {
            return this.crematoriumDetails &&
                this.crematoriumDetails.isStaffPinSet &&
                [this.crematoriumDetails.nameSearchOption, this.crematoriumDetails.pinSearchOption, this.crematoriumDetails.dateSearchOption].includes(SearchOption.STAFF_ONLY) &&
                !this.isStaff
        },
        isStaff() {
            return store.isStaffPinEntered()
        },
    }
}