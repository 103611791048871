const SessionStorageKeys = Object.freeze({
    STAFF_PIN: "staffPin",
})

const staffPin = sessionStorage.getItem(SessionStorageKeys.STAFF_PIN) || null

export default {
    state: {
        staffPin,
        crematoriumDetails: null,
        entry: null,
    },
    isStaffPinEntered() {
        return !!this.state.staffPin
    },
    setStaffPin(newValue) {
        this.state.staffPin = newValue || null

        if (this.state.staffPin) {
            sessionStorage.setItem(SessionStorageKeys.STAFF_PIN, this.state.staffPin)
        } else {
            sessionStorage.removeItem(SessionStorageKeys.STAFF_PIN)
        }
    },
    clearStaffPin() {
        this.setStaffPin(null)
    },
    setCrematoriumDetails(newValue) {
        this.state.crematoriumDetails = newValue
    },
    setEntry(newValue) {
        this.state.entry = newValue
    },
    clearEntry() {
        this.state.entry = null
    },
}
