import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import SearchByName from '@/views/SearchByName.vue'
import SearchByPIN from '@/views/SearchByPIN.vue'
import SearchByDate from '@/views/SearchByDate.vue'
import SearchResults from '@/views/SearchResults.vue'
import Entry from '@/views/Entry.vue'
import Book from '@/views/Book.vue'
import Staff from '@/views/Staff.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isHome: true,
    }
  },
  {
    path: '/search-by-name',
    name: 'SearchByName',
    component: SearchByName,
  }, 
  {
    path: '/search-by-pin',
    name: 'SearchByPIN',
    component: SearchByPIN,
  },
  {
    path: '/search-by-date',
    name: 'SearchByDate',
    component: SearchByDate,
  },
  {
    path: '/search-results',
    name: 'SearchResults',
    component: SearchResults,
    props: route => ({ text: route.query.text }),
  },
  {
    path: '/entry',
    name: 'Entry',
    component: Entry,
    props: route => ({
      entryId: route.query.entryId ? parseInt(route.query.entryId) : undefined,
      pin: route.query.pin,
    }),
    meta: {
      fullScreen: true,
    },
  },
  {
    path: '/book',
    name: 'Book',
    component: Book,
    props: route => ({
      day: parseInt(route.query.day),
      month: route.query.month,
    }),
    meta: {
      fullScreen: true,
    },
  },
  {
    path: '/staff',
    name: 'Staff',
    component: Staff,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
