<template>
  <div>
    <h1>
      Search By PIN
    </h1>

    <p> 
      Please enter your unique PIN or the number on your swipecard and tap/click on ‘Find’.
    </p>

    <form @submit.prevent="onSubmit">
      <div class="input-line">
        <input type="text" required maxlength="50" ref="text" v-model.trim="text" />

        <button type="submit" class="submit-button">
          Find
        </button>

        <button type="reset" class="clear-button" :class="{ 'highlight': highlight === 'CLEAR' }" @click="onClear" @mousedown="onVirtualKeyMouseDown('CLEAR')">
          Clear
        </button>
      </div>

      <template v-if="useKeyboard">
        <keyboard-row :keys="topRow" :highlight="highlight" @virtual-key="onVirtualKey" @mousedown="onVirtualKeyMouseDown" />

        <keyboard-row :keys="middleRow" :highlight="highlight" @virtual-key="onVirtualKey" @mousedown="onVirtualKeyMouseDown" />

        <keyboard-row :keys="middleRow2" :highlight="highlight" @virtual-key="onVirtualKey" @mousedown="onVirtualKeyMouseDown" />

        <keyboard-row :keys="bottomRow" :highlight="highlight" @virtual-key="onVirtualKey" @mousedown="onVirtualKeyMouseDown" />
      </template>

    </form>
  </div>
</template>

<script>
import KeyboardRow from '@/components/KeyboardRow.vue'
import CrematoriumDetailsMixin from '@/mixins/CrematoriumDetailsMixin'
import BodyInputMixin from '@/mixins/BodyInputMixin'
import { PIN_CHARACTERS } from '@/data/characters'

export default {
  name: 'SearchByName',
  components: {
    KeyboardRow,
  },
  mixins: [ CrematoriumDetailsMixin, BodyInputMixin ],
  data() {
    return {
      allowedKeys: PIN_CHARACTERS,
      topRow: [ ...'0123456789'.split(''), 'DELETE' ],
      middleRow: 'QWERTYUIOP'.split(''),
      middleRow2: 'ASDFGHJKL'.split(''),
      bottomRow: 'ZXCVBNM'.split(''),
    }
  },
  mounted() {
    if (!this.allowSearchByPin) {
      this.$router.replace('/')
    } else if (!this.useKeyboard) {
      this.$refs.text.focus()
    }
  },
  methods: {
    onSubmit() {
      this.$router.push({ path: 'entry', query: { crematorium: this.$route.query.crematorium, pin: this.text }})
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_button';

  .submit-button:not(.highlight) {
    @include master-button-bg($submit-bg-colour);
  }

  .clear-button:not(.highlight) {
    @include master-button-bg($clear-bg-colour);
  }

  .delete-button:not(.highlight) {
    @include master-button-bg($delete-bg-colour);
  }
</style>