import store from '@/infrastructure/store'

const HIGHLIGHT_TIMEOUT_MS = 500

const DELETE_KEY_CODE = 46
const BACKSPACE_KEY_CODE = 8
const ENTER_KEY_CODE = 8
const SPACEBAR_KEY_CODE = 32

function isDeleteKey(event) {
    return !!event && event.code === DELETE_KEY_CODE || event.key === 'Delete' || event.keyCode === DELETE_KEY_CODE || event.which === DELETE_KEY_CODE
}

function isBackspaceKey(event) {
    return !!event && event.code === BACKSPACE_KEY_CODE || event.key === 'Backspace' || event.keyCode === BACKSPACE_KEY_CODE || event.which === BACKSPACE_KEY_CODE
}

function isEnterKey(event) {
    return !!event && event.code === ENTER_KEY_CODE || event.key === 'Enter' || event.keyCode === ENTER_KEY_CODE || event.which === ENTER_KEY_CODE
}

function isSpacebar(event) {
    return !!event && event.code === SPACEBAR_KEY_CODE || event.key === 'Spacebar' || event.keyCode === SPACEBAR_KEY_CODE || event.which === SPACEBAR_KEY_CODE
}

function isControlElement(event) {
    return !!event && !!event.target && !!event.target.tagName && ['INPUT','BUTTON','A'].indexOf(event.target.tagName.toUpperCase()) >= 0
}

export default {
    data() {
        return {
            sharedState: store.state,
            textField: '',
            allowedKeys: '', // Set by component
            highlight: null,
            highlightTimerHandle: null,
            eventHandlerAdded: false,
        }
    },
    mounted() {
        if (this.useKeyboard) {
            document.addEventListener('keydown', this.onDocumentKeyDown)
            this.eventHandlerAdded = true
        }
    },
    beforeDestroy() {
        if (this.eventHandlerAdded) {
            document.removeEventListener('keydown', this.onDocumentKeyDown)
        }
    },
    methods: {
        onDocumentKeyDown(event) {
            let handled = false

            if (isControlElement(event)) {
                // Do nothing
            } else if (isDeleteKey(event)) {
                this.onDelete()
                handled = true
                this.setHighlight('DELETE')
            } else if (isBackspaceKey(event)) {
                this.onDelete()
                handled = true
            } else if (isEnterKey(event)) {
                if (typeof this.onSubmit === 'function') {
                    this.onSubmit()
                    handled = true
                }
            } else if (isSpacebar(event)) {
                handled = this.onVirtualKey(' ')
            } else {
                handled = this.onVirtualKey(event.key)
            }
            
            if (handled) {
                event.stopImmediatePropagation()
                event.preventDefault()
            }
        },
        onVirtualKey(key) {
            if (key === 'DELETE') {
                this.onDelete()
            } else if (this.allowedKeys.includes(key)) {
                const currentText = this.text || ''

                if (key !== ' ' || (currentText.length > 0 && !currentText.endsWith(' '))) {
                    this.text = currentText + key.toUpperCase()
                }
                
                this.setHighlight(key)
                return true
            }

            return false
        },
        onVirtualKeyMouseDown(key) {
            this.setHighlight(key)
        },
        onDelete() {
            const trimmed = (this.text || '').trim()

            if (trimmed.length > 0) {
                this.text = trimmed.substring(0, trimmed.length - 1)
            } else {
                this.text = ''
            }

            this.setHighlight('DELETE')
        },
        onClear() {
            this.text = ''
            this.setHighlight('CLEAR')
        },
        setHighlight(key) {
            this.highlight = key

            if (this.highlightTimerHandle) {
                clearTimeout(this.highlightTimerHandle)
            }

            this.highlightTimerHandle = setTimeout(() => {
                this.highlightTimerHandle = null
                this.highlight = null
            }, HIGHLIGHT_TIMEOUT_MS)
        },
    },
    computed: {
        useKeyboard() {
            return this.sharedState.crematoriumDetails && this.sharedState.crematoriumDetails.useKeyboard
        },
        text: {
            get() {
                return this.textField
            },
            set(value) {
                this.textField = (value || '').replace(' ', ' ').toUpperCase()

                if (typeof this.onTextChange === 'function') {
                    this.onTextChange()
                }
            },
        }
    },
}
