<template>
    <div class="book-wrap">
        <div v-if="hasInvalidParameters">
            Oops, something has gone wrong
        </div>
        <template v-else>
            <div>
                <select v-model="selectedMonth" :disabled="isLoading" @input="dateChanged">
                    <option v-for="month in months" :key="month.key" :value="month">
                        {{ month.name }}
                    </option>
                </select>

                <select v-model="selectedDay" :disabled="isLoading" @input="dateChanged">
                    <template v-if="selectedMonth">
                        <option v-for="day in selectedMonth.days" :key="day" :value="day">
                            {{ day }}
                        </option>
                    </template>
                </select>
                
                <button type="button" @click="previous" :disabled="isLoading || currentPage <= 1">
                    &laquo; Previous Page
                </button>

                <button type="button" @click="next" :disabled="isLoading || currentPage >= pageCount">
                    Next Page &raquo;
                </button>

                <button type="button" @click="zoomOut" :disabled="isLoading">
                    Zoom Out -
                </button>

                <button type="button" @click="zoomIn" :disabled="isLoading">
                    Zoom In +
                </button>
                
                <router-link to="/" class="button">
                    Return Home
                </router-link>
            </div>

            <div v-if="isLoading">
                Loading...
            </div>
            <div v-else-if="hasErrored">
                {{ errorMessage || 'Sorry, an error has occurred' }}
            </div>
            <dear-flip
                    v-else-if="pageCount && selectedDay && selectedMonth"
                    ref="pages"
                    :page-images="pageImages"
                    :current-page.sync="currentPage"
                    class="dear-flip" />

            <p class="help-text">
                Tap or click on an entry to zoom
            </p>
        </template>
    </div>
</template>

<script>
// @ is an alias to /src
import DearFlip from '@/components/DearFlip'
import months from '@/data/months'
import store from '@/infrastructure/store'

export default {
    name: 'book',
    components: {
        DearFlip
    },
    props: {
        day: { type: Number, required: true },
        month: { type: String, required: true },
    },
    inject: ['api'],
    data() {
        return {
            sharedState: store.state,
            isLoading: false,
            hasErrored: false,
            errorMessage: '',
            pageCount: 0,
            months,
            currentPage: 1,
            selectedDay: null,
            selectedMonth: null,
        }
    },
    mounted() {
        this.selectedDay = this.day
        this.selectedMonth = months.find(x => x.key === this.month)

        this.loadData()
    },
    methods: {
        loadData() {
            if (this.hasInvalidParameters) {
                return
            }

            this.isLoading = true
            this.hasErrored = false
            
            this.api.getPageCount(this.$route.query.crematorium, this.selectedMonth.number, this.selectedDay, this.sharedState.staffPin)
                .then(response => {
                    this.pageCount = response.data.pageCount
                })
                .catch(error => {
                    console.error(error)
                    this.errorMessage = 'Sorry, we were unable to load the book at this time. Please try again later.'
                    this.hasErrored = true
                })
                .then(() => {
                    this.isLoading = false
                })
        },

        previous() {
            this.$refs.pages.previous()
        },

        next() {
            this.$refs.pages.next()
        },

        zoomOut() {
            this.$refs.pages.zoomOut()
        },

        zoomIn() {
            this.$refs.pages.zoomIn()
        },

        dateChanged() {
            this.$nextTick(() => {
                this.$router.replace({ path: '/book', query: { crematorium: this.$route.query.crematorium, day: this.selectedDay, month: this.selectedMonth.key } })
                this.loadData()
            })
        },
    },
    computed: {
        hasInvalidParameters() {
            return !this.selectedDay || !this.selectedMonth
        },
        selectedMonthNumber() {
            return this.selectedMonth ? this.selectedMonth.number : null
        },
        pageImages() {
            const pageImages = []

            if (this.selectedMonthNumber && this.selectedDay) {
                // Page index is 1 based
                for (let pageIndex = 1; pageIndex <= this.pageCount; pageIndex++) {
                    const url = this.api.getPageUrl(this.$route.query.crematorium, this.selectedMonthNumber, this.selectedDay, pageIndex)
                    pageImages.push(url)
                }
            }

            return pageImages
        },
    }
}
</script>

<style scoped lang="scss">
    .book-wrap {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .dear-flip {
        flex-grow: 1;
    }

    select {
        padding: 10px 10px;
        border-radius: 3px;
        font-size: 12pt;
        margin: 10px;
        border: 1px solid black;
    }
</style>