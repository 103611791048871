export default [
    { number: 1, key: 'jan', name: 'January', days: 31 },
    { number: 2, key: 'feb', name: 'February', days: 29 /* Always 29 as no year is selected */ },
    { number: 3, key: 'mar', name: 'March', days: 31 },
    { number: 4, key: 'apr', name: 'April', days: 30 },
    { number: 5, key: 'may', name: 'May', days: 31 },
    { number: 6, key: 'jun', name: 'June', days: 30 },
    { number: 7, key: 'jul', name: 'July', days: 31 },
    { number: 8, key: 'aug', name: 'August', days: 31 },
    { number: 9, key: 'sep', name: 'September', days: 30 },
    { number: 10, key: 'oct', name: 'October', days: 31 },
    { number: 11, key: 'nov', name: 'November', days: 30 },
    { number: 12, key: 'dec', name: 'December', days: 31 },
]
