import axios from 'axios'
import { API_URL_PREFIX } from '@/settings'

export default class {
    getCrematoriumDetails(hostname) {
        const params = { hostName: hostname || this.hostName }

        return axios.get(this.apiUrlPrefix + 'Crematorium', { params })
    }

    checkStaffPin(hostName, staffPin) {
        const url = this.apiUrlPrefix + 'Crematorium/CheckStaffPin'
        const params = { hostName: hostName || this.hostName, staffPin }

        return axios.post(url, params)
    }

    getEntry(hostName, entryId, pin, staffPin) {
        const params = { hostName: hostName || this.hostName, staffPin, entryId, pin }

        return axios.post(this.apiUrlPrefix + 'Entry/Retrieve', params)
    }

    searchByName(hostName, nameSearch, staffPin) {
        const params = { hostName: hostName || this.hostName, staffPin, nameSearch }

        return axios.post(this.apiUrlPrefix + 'Entry/SearchByName', params)
    }

    getPageCount(hostName, monthNumber, dayOfMonth, staffPin) {
        const params = { hostname: hostName || this.hostName, staffPin, monthNumber, dayOfMonth }

        return axios.post(this.apiUrlPrefix + 'Page/GetPageCount', params)
    }

    getPageUrl(hostName, month, day, pageIndex) {
        const url = this.pageImageBaseUrl(hostName) + '&month=' + month + '&day=' + day + '&pageIndex=' + pageIndex
        return url
    }

    getPageUrlById(hostName, pageId) {
        const url = this.pageImageBaseUrl(hostName) + '&pageId=' + pageId
        return url
    }

    getMemorialImageSrc(memorialId) {
        const url = this.urlPrefix + 'image/memorial?memorialId=' + memorialId
        return url
    }   

    pageImageBaseUrl(hostName) {
        return this.urlPrefix + 'image/page?hostName=' + (hostName || this.hostName)
    }

    get apiUrlPrefix() {
        return this.urlPrefix + 'api/'
    }

    get urlPrefix() {
        return API_URL_PREFIX
    }

    get hostName() {
        return window.location.hostname
    }
}
