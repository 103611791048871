<template>
    <div>
        <button v-for="key in keys" :key="key" type="button"
                :class="{ 'highlight': hasHighlight(key), 'space': key === ' ', 'delete': key === 'DELETE' }"
                @click="$emit('virtual-key', key)"
                @mousedown="$emit('mousedown', key)">
            {{ key === ' ' ? 'SPACE' : key === 'DELETE' ? 'DEL' : key }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'KeyboardRow',
    props: {
        keys: { type: Array },
        highlight: { type: String, required: false, default: null }
    },
    methods: {
        hasHighlight(key) {
            if (this.highlight && key && this.highlight.toUpperCase() === key.toUpperCase()) {
                return true
            }

            return false
        }
    },
}
</script>

<style scoped lang="scss">
    @import '@/scss/_button';
        
    button {
        min-width: 60px;
        margin: 4px;
        padding: 10px;

        &.space {
            min-width: 20rem;
            margin-top: 8px;
        }

        &.delete:not(.highlight) {
            @include master-button-bg($delete-bg-colour);
        }
    }
</style>
