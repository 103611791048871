<template>
    <div class="flip-wrap" ref="flipWrap">
        <div ref="flipbookImageContainer"></div>
    </div>
</template>

<script>
// See https://stackoverflow.com/a/45775204
// See https://eslint.org/docs/user-guide/configuring#specifying-environments
/* eslint-env jquery */

export default {
    name: 'pages-dear-flip',
    data() {
        return {
            flipBook: null,
        }
    },
    props: {
        pageImages: { type: Array },
        currentPage: { type: Number, require: false, default: null },
    },
    mounted() {
        this.init()

        window.addEventListener('resize', () => {
            if (this.flipBook) {
                const display = this.$refs.flipbookImageContainer.style.display

                this.$refs.flipbookImageContainer.style.display = "none"

                // Get height without flipbook
                const heightToUse = this.getHeight()
                
                this.flipBook.height(heightToUse)
                this.$refs.flipbookImageContainer.style.display = display
            }
        })
    },
    beforeDestroy() {
        if (this.flipBook) {
            this.flipBook.dispose()
            this.flipBook = null
        }
    },
    methods: {
        previous() {
            if (this.flipBook) {
                this.flipBook.prev()
            }
        },
        next() {
            if (this.flipBook) {
                this.flipBook.next()
            }
        },
        zoomIn() {
            if (this.flipBook) {
                this.flipBook.zoom(1)
            }
        },
        zoomOut() {
            if (this.flipBook) {
                this.flipBook.zoom(0)
            }
        },
        getHeight() {
            return this.$refs.flipWrap.clientHeight
        },
        init() {
            // See https://dearflip.com/docs/dearflip-jquery/getting-started/
            const options = {
                source: this.pageImages,
                webgl: true,
                soundEnable: false,
                scrollWheel: true,

                height: this.getHeight(),

                //valid controlnames:
                //altPrev,pageNumber,altNext,outline,thumbnail,zoomIn,zoomOut,fullScreen,share
                //more,download,pageMode,startPage,endPage,sound
                allControls: "startPage,altPrev,pageNumber,altNext,endPage,zoomIn,zoomOut",
                moreControls: "",
                hideControls: "",

                //color value in hexadecimal
                backgroundColor: "transparent",

                text: {
                    gotoFirstPage: "First Page",
                    gotoLastPage: "Last Page",
                },

                controlsPosition: 'hide', // TODO Unable to use constant

                beforeFlip: flipBook => this.$emit('update:current-page', flipBook.target._activePage),

                zoomRatio: 3,

                // Bespoke option
                clickToZoom: true,
            }

            this.flipBook = $(this.$refs.flipbookImageContainer).flipBook(this.pageImages, options)
        },
    },
    computed: {
        pageCount() {
            return this.pageImages ? this.pageImages.length : 0
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/_mixins';

.flip-wrap {
    overflow-y: hidden;

    @include ie-only {
        // Internet Explorer does not stretch to 
        height: 500px;
    }
}
</style>

<style lang="scss">
$btn-height: 3rem;
$padding: 20px;

.df-container.df-floating > .df-ui-prev, .df-container.df-floating > .df-ui-next {
    top: calc(50% - #{$btn-height / 2} - #{$padding});
    opacity: 0.5;
    transition: opacity 0.2s;

    &::before {
        position: absolute;
        border-radius: 50%;
        color: white;
        padding: $padding;
        font-size: $btn-height;
        background-color: rgba(0, 0, 0, 0.7);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    }

    &:hover {
        opacity: 1;
        transition: opacity 0.3s;
    }

    &.df-ui-prev {
        &::before {
            left: 0;
        }
    }

    &.df-ui-next {
        &::before {
            right: 0;
        }
    }
}
</style>