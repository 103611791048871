<template>
    <div class="entry-wrap">
        <div v-if="invalidParameters">
            Sorry, something has gone wrong
        </div>
        <div v-else-if="isLoading">
            Retrieving entry details...
        </div>
        <div v-else-if="hasErrored">
            <p>
                {{ errorMessage || 'Sorry, an error has occurred' }}
            </p>

            <button type="button" @click="$router.go(-1)">
                Go Back
            </button>
        </div>
        <template v-else>
            <h1>
                Entry for {{ entry.lastName + ', ' + entry.firstName }}
            </h1>

            <div>
                <button type="button" @click="launchEntryFlip" :disabled="mode === 1">
                    View Page
                </button>

                <button v-if="entry.memorials && entry.memorials.length > 0" type="button" @click="launchMemorialFlip" :disabled="mode === 2">
                    View Memorials
                </button>

                <button type="button" @click="previous" :disabled="isLoading || currentPage <= 1">
                    &laquo; Previous Page
                </button>

                <button type="button" @click="next" :disabled="isLoading || currentPage >= pageCount">
                    Next Page &raquo;
                </button>

                <button type="button" @click="zoomOut" :disabled="isLoading">
                    Zoom Out -
                </button>

                <button type="button" @click="zoomIn" :disabled="isLoading">
                    Zoom In +
                </button>

                <router-link to="/" class="button">
                    Return Home
                </router-link>
            </div>

            <dear-flip v-if="mode" :page-images="pageImages" :current-page.sync="currentPage" ref="dearFlip" class="dear-flip" />
            
            <p class="help-text">
                Tap or click on an entry to zoom
            </p>
        </template>
    </div>
</template>

<script>
import store from '@/infrastructure/store'
import DearFlip from '@/components/DearFlip'

export default {
    name: 'entry',
    components: { DearFlip },
    props: {
        entryId: { type: Number, required: false, default: null },
        pin: { type: String, required: false, default: null },
    },
    inject: ['api'],
    data() {
        return {
            sharedState: store.state,
            invalidParameters: false,
            isLoading: true,
            hasErrored: false,
            errorMessage: '',
            mode: 1,
            currentPage: 1,
        }
    },
    mounted() {
        if (!this.entryId && !this.pin) {
            this.invalidParameters = true
            return
        }

        this.isLoading = true
        this.hasErrored = false

        this.api.getEntry(this.$route.query.crematorium, this.entryId, this.pin, this.sharedState.staffPin)
            .then(response => {
                store.setEntry(response.data)
            })
            .catch(error => {
                console.error(error)

                if (error.response && error.response.status === 404) {
                    this.errorMessage = 'Sorry, the entry has not been found'
                } else {
                    this.errorMessage = 'Sorry, we were unable to load the entry at this time. Please try again later.'
                }

                this.hasErrored = true
            })
            .then(() => {
                this.isLoading = false
            })
    },
    methods: {
        launchEntryFlip() {
            this.changeMode(1)
        },
        launchMemorialFlip() {
            this.changeMode(2)
        },
        changeMode(newMode) {
            this.mode = null
            this.currentPage = 1
            this.$nextTick(() => this.mode = newMode)
        },

        previous() {
            this.$refs.dearFlip.previous()
        },

        next() {
            this.$refs.dearFlip.next()
        },

        zoomOut() {
            this.$refs.dearFlip.zoomOut()
        },

        zoomIn() {
            this.$refs.dearFlip.zoomIn()
        },
    },
    computed: {
        entry() {
            return this.sharedState.entry
        },
        pageImageSrc() {
            return this.api.getPageUrlById(this.$route.query.crematorium, this.entry.pageId)
        },
        memorialImagesSrc() {
            return this.entry && this.entry.memorials ? this.entry.memorials.map(x => this.api.getMemorialImageSrc(x.memorialId)) : []
        },
        pageImages() {
            switch (this.mode) {
                case 1:
                    return [ this.pageImageSrc ]
                case 2:
                    return this.memorialImagesSrc
                default:
                    return []
            }
        },
        pageCount() {
            return this.pageImages.length
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/_button';

a.button, button {
    font-size: 0.85rem;
    margin: 6px;
}

.entry-wrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.dear-flip {
    flex-grow: 1;
}
</style>